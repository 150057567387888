//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';

export default Vue.extend({
  computed: {
    dictionary() {
      return this.params.data?.dictionary || []
    }
  },
  methods: {
    handleOpenDictionary(dicName) {
      this.$router.push(`/settings/speech-setting?dicName=${dicName}`);
    }
  }
});
