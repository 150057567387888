var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.dictionary, function(dicName, index) {
      return _c(
        "a-tag",
        {
          key: index,
          staticStyle: { cursor: "pointer", padding: "2px 8px" },
          attrs: { color: "pink" },
          on: {
            click: function($event) {
              return _vm.handleOpenDictionary(dicName)
            }
          }
        },
        [_vm._v(" " + _vm._s(dicName) + " ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }